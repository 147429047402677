.skill-image-containeasdr {
    display: inline-block; /* Erlaubt das Einreihen neben anderen Elementen */
    position: relative; /* Für die Positionierung des Counters oder weiterer Elemente */
    padding: 5px; /* Etwas Abstand um das Bild */
    box-sizing: border-box; /* Damit Padding und Border die Größe nicht verändern */
  }
  
  .skill-container {
    width: auto; /* Oder feste Größe, wenn nötig */
    height: 100px; /* Anpassen basierend auf dem Layout */
    object-fit: contain; /* Bewahrt das Seitenverhältnis ohne zu beschneiden */
    border: 2px solid #ccc; /* Einfache, stilvolle Umrandung */
    border-radius: 8px; /* Runde Ecken für die Umrandung */
    background-color: #f9f9f9; /* Leichter Hintergrund */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtiler Schatten für Tiefe */
  }
  
  .skill-container:hover {
    border-color: #007bff; /* Highlight Farbe beim Hovern */
  }
  