.depth-navigation {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.depth-navigation button {
    border: none;
    background-color: transparent;
    cursor: pointer;
    font-size: 24px;
    color: #007bff; /* Bootstrap primary color */
}

.depth-navigation button:disabled {
    color: #6c757d; /* Bootstrap secondary color */
}

.depth-navigation span {
    font-size: 16px;
}
