.icon-img {
    width: 95%;
    height: 95%;
    position: relative;
}

.icon-img:hover {
    transform: scale(1.1);
    transition: transform 0.2s;
}

.item-icon-container {
    position: relative;
    display: inline-block;
}

.item-count {
    position: absolute;
    bottom: 5px;
    right: 5px;
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    font-size: 0.75rem;
    padding: 2px;
    border-radius: 3px;
}

.item_description{
    font-style: italic;
}