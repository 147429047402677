/* Ore.css */
div .ore {
	cursor: pointer;
	width: 50px; /* oder passende Größe */
	height: 50px; /* oder passende Größe */
	border: none;
	margin: 2px;
}

/* Anpassung für jedes Ore basierend auf seiner ID */
div .ore-1 {
	background-color: #f4e7da; /* Sandfarbe */
}

div .ore-2 {
	background-color: #8b8c7a; /* Steinfarbe */
}

div .ore-3 {
	background-color: #b87333; /* Eisenfarbe, charakteristisches Rostbraun */
}

div .ore-4 {
	background-color: #c87533; /* Kupferfarbe, leuchtendes Orange-Braun */
}

div .ore-5 {
	background-color: #ffd700; /* Goldfarbe, glänzendes Gelb */
}

div .ore-6 {
	background-color: #b9f2ff; /* Diamantfarbe, leuchtendes Hellblau */
}

div .ore-7 {
	background-color: #96c; /* Mystic Quartz, magisches Violett */
}

div .ore-8 {
	background-color: #0cf; /* Galactic Stone, tiefes Kosmikblau */
}

div .ore-9 {
  background-color: #19130d;
}

div .ore-99 {
	background-color: rgb(80, 34, 13);
}

/* Ergänzungen für Hover-Effekt, um Interaktivität zu signalisieren */
.ore:hover {
	opacity: 0.8;
}

.health-bar-container {
	background-color: #eee;
	width: 100%;
	height: 10px;
	border-radius: 5px;
	overflow: hidden;
}

.health-bar {
	background-color: #4caf50;
	height: 100%;
	transition: width 0.3s ease;
}
