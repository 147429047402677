.statusEffects {
  display: flex;
  align-items: center;
  gap: 10px; /* Abstand zwischen den Boost-Icons */
  padding: 10px;
  background-color: #f0f0f0; /* Hintergrundfarbe der Boost-Anzeige */
  border-radius: 8px; /* Abgerundete Ecken */
}

.boost-icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px; /* Größe der Boost-Icons */
  height: 40px;
  background-color: #ffffff; /* Hintergrundfarbe der Icons */
  border: 1px solid #dddddd; /* Rand der Icons */
  border-radius: 50%; /* Runde Icons */
  cursor: pointer;
}

.boost-icon svg {
  width: 24px; /* Größe des FontAwesome Icons */
  height: 24px;
  color: #555555; /* Farbe des Icons */
}

/* Popover Customisierung (optional, abhängig von deinem Design) */
.popover {
  max-width: 200px; /* Maximale Breite des Popovers */
}

.popover-header {
  background-color: #007bff;
}