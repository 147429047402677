.upgradeScreen {
	height: 33vh; /* Mobile Ansicht */
	overflow-y: scroll;
}

@media (min-width: 768px) {
	.upgradeScreen {
		width: 30%;
		height: 100vh; /* Desktop Ansicht */
		float: right;
	}
}
