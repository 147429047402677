body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
		"Droid Sans", "Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

div .full-y {
	height: 100%;
}

div .full-x {
	width: 100%;
}

div .card-title {
	margin-bottom: 0px;
}

.float-right {
	float: right
}

.italic {
	font-style: italic;
}

.vh-70{
	max-height: 70vh;
	overflow-y: auto;
}

.text-center {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%; /* Oder eine spezifische Höhe, je nach Bedarf */
}

.text-background h3 {
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparenter schwarzer Hintergrund */
    color: white; /* Weiße Schriftfarbe für besseren Kontrast */
    padding: 10px; /* Etwas Platz um den Text */
    border-radius: 5px; /* Optional: Abgerundete Ecken */
    display: inline-block; /* Verhindert, dass der Hintergrund die gesamte Breite einnimmt */
}

body {
	overflow-x: hidden
}

button.btn {
    border-radius: 0;
}

.list-group-item {
	border-radius: 0;
}

div.card {
	border-radius: 0;
}

.bonus-overview-tab {
	max-height: 95vh;
	overflow-y: auto;
}

.align-center {
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;;
}

.align-right {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	text-align: right;
}

.legendary {
	background-color: #ff8000; /* Orangefarbener Hintergrund für Legendary-Zeilen */
	color: #ffffff; /* Weiße Textfarbe für bessere Lesbarkeit */
	font-weight: bold; /* Fettdruck für den Text */
	transition: background-color 0.3s ease; /* Sanfter Übergangseffekt für den Hintergrund */
}

.row.legendary {
	border-left: 5px solid gold; /* Goldener linker Rand als Highlight */
	box-shadow: 0px 0px 10px gold; /* Leichter Schatten für einen "glowing" Effekt */
}

.legendary:hover {
	background-color: #e68a00; /* Dunklerer Hintergrund beim Hovern für interaktiven Effekt */
}

div .modal-content {
	border-radius: 0;
}

div .modal-header, div .modal-footer {
    border-color: #0f0; /* Neon-Grüne Linien */
}

div .modal-title {
    font-family: 'Orbitron', sans-serif; /* Futuristische Schriftart */
}
/*

div .btn-primary {
    background-color: #0f0;
    border-color: #0f0;
}

div .btn-secondary {
    background-color: #555;
    border-color: #666;
}

div .btn-primary:hover {
    background-color: #2f2;
    border-color: #2f2;
}

div .btn-secondary:hover {
    background-color: #666;
    border-color: #777;
}
*/

.bold {
	font-weight: bold;
}