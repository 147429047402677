.miner-container {
    overflow-y: auto;
    height: 80vh;
}

.mining-screen {
    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
    -webkit-touch-callout: none;
    /* iOS Safari */
}

.ore-image-container img {
    pointer-events: none;
}