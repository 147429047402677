.planet-image-container {
	display: inline-block;
	overflow: hidden;
	border: 2px solid #fff; /* Weißer Rahmen */
	border-radius: 5px; /* Leicht abgerundete Ecken */
}

.planet-image-container img {
	transition: transform 0.3s ease;
	display: block;
	width: 100%; /* Stellt sicher, dass das Bild die Containerbreite anpasst */
}

.planet-image-container:hover img {
	transform: scale(1.1); /* Leichter Zoom beim Hover */
}
