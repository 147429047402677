.slot {
    width: 100px;
    height: 100px;
    background-color: #ddd;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: 10px;
    border: 2px solid #ccc;
}

.slot-icon {
    width: 60%;
    height: 60%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}