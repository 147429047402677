.creditsDisplay {
  position: relative;
  text-align: center; /* Zentriere den Text */
  font-size: 24px; /* Größe des Haupttextes */
  color: #212121; /* Farbe des Textes, an dein Design anpassen */
  font-family: 'Arial', sans-serif; /* Schriftart, anpassen nach Bedarf */
}

.creditChange {
  position: absolute;
  left: 50%;
  transform: translateX(-50%); /* Zentriert den animierten Text horizontal */
  bottom: -30px; /* Position unterhalb des Haupttextes */
  font-size: 20px; /* Größe des animierten Textes */
  color: #4CAF50; /* Farbe für positive Änderungen */
  font-weight: bold; /* Macht den Text fett */
}

.creditChange.negative {
  color: #F44336; /* Farbe für negative Änderungen */
}
