.timer-box {
    padding: 10px;
    border: 1px solid #ccc;
    margin: 10px;
  }
  
  .timer-box.timeout {
    color: #fff;
    background-color: #ff0000;
    border-color: #ff0000;
  }