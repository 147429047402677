/* styles.css */
.upgrade-button-container {
    display: flex;
    flex-wrap: wrap;
    overflow-x: auto;
    overflow-y: hidden;
    gap: 10px; /* Abstand zwischen den Buttons */
  }
  
  .upgrade-button {
    flex: 0 1 auto; /* Erlaubt Buttons zu schrumpfen aber nicht zu wachsen, behält ihre Basisbreite bei */
    width: 50px; /* Setze eine feste Größe für Quadratische Form */
    height: 50px;
    padding: 0; /* Entferne Padding, um das Bild besser anzupassen */
    background-size: cover;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
  }
  
  .upgrade-button-col {
    padding-left: 2%;
    padding-right: 2%;
  }
  .upgrade-button-row {
    width: 100%;
  }