.planet-travel-tab {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    /* Stelle sicher, dass der Tab die volle Höhe einnimmt */
    background-size: cover;
    /* Hintergrundbild deckt den ganzen Bereich ab */
    background-position: center;
    /* Zentriert das Hintergrundbild */
}

.planet-navigation {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 20px;
    /* Abstand nach unten für den "Fliegen" Button */
}

.planet-navigation h3 {
    margin: 0;
    /* Entfernt den Standardabstand um das h3 Element */
    color: #fff;
    /* Weiße Textfarbe für bessere Sichtbarkeit */
}

/* Stil für die Navigationsbuttons */
.planet-navigation button {
    background-color: #007bff;
    /* Blaue Hintergrundfarbe */
    border: none;
    /* Entfernt den Standardrahmen */
    color: white;
    /* Weiße Textfarbe */
    padding: 10px 15px;
    /* Innenabstand */
    font-size: 1rem;
    /* Schriftgröße */
    cursor: pointer;
    /* Cursor als Zeiger */
    border-radius: 5px;
    /* Abgerundete Ecken */
}

.planet-navigation button:hover {
    background-color: #0056b3;
    /* Dunkleres Blau beim Hovern */
}
/* Stil für das Bestätigungsmodal */
.modal-content {
    background-color: #f8f9fa;
    /* Hintergrundfarbe */
    color: #333;
    /* Textfarbe */
    border-radius: 10px;
    /* Abgerundete Ecken */
}

.modal-header,
.modal-footer {
    border-bottom: none;
    /* Entfernt den unteren Rand im Header */
    border-top: none;
    /* Entfernt den oberen Rand im Footer */
}

.modal-footer button {
    padding: 5px 10px;
    /* Reduzierter Innenabstand */
}

.planet-navigation {
    height: 50vh
}

.planet-ores {
    height: 30vh
}

.requirements-complete {
    color: green;
}

.requirements-incomplete {
    color: red;
    text-decoration: line-through;
}

.travel-modal-content {
    background-color: #000; 
    border: 2px solid #007bff;
    color: #fff;
    background-image: url('../../../../public/assets/background/travelmodal.webp');
    background-size: cover;
    background-repeat: no-repeat;
}
