.ore-image-container img {
    transition: transform 0.3s ease;
    max-width: 100%;
    height: auto;
}

.ore-image-container:hover img {
    transform: scale(1.1);
}

.ore-image-container {
    position: relative; /* Ermöglicht die absolute Positionierung der Anzahl innerhalb des Containers */
    display: inline-block; /* Oder flex, abhängig von deinem Layout */
  }
  
  .ore-image-container img {
    width: 100%; /* Oder eine feste Größe, je nach Bedarf */
    height: auto;
  }
  
  .ore-count {
    position: absolute;
    right: 5px; /* Abstand von der rechten Kante des Containers */
    bottom: 5px; /* Abstand von der unteren Kante des Containers */
    color: #ffffff; /* Farbe des Textes */
    background-color: rgba(0, 0, 0, 0.5); /* Hintergrundfarbe mit Transparenz */
    padding: 2px 5px; /* Innenabstand */
    border-radius: 5px; /* Abgerundete Ecken */
    font-size: 0.8em; /* Schriftgröße */
  }

  .ore-container {
    position: relative;
    width: 100px; /* Breite des Containers anpassen */
    height: 100px; /* Höhe des Containers anpassen */
    display: inline-block;
  }
  
  .ore-container img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Passt das Bild an, ohne das Seitenverhältnis zu verzerren */
  }
  
  .ore-count {
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Hintergrund der Anzahl, für bessere Lesbarkeit */
    color: white; /* Farbe der Schrift */
    padding: 2px 5px; /* Abstand innen */
    font-size: 0.8rem; /* Schriftgröße anpassen */
  }
  
  .mining-screen > div > div > .ore-container {
    width: auto;
    height: 100%;
  }