.inventory-slot {
    width: 100px;
    height: 100px;
    background-color: #f0f0f0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: 5px;
    border: 1px solid #bbbbbb;
    margin: 5px;
    cursor: pointer;

    flex: 1 1 100px;
}

.inventory-items > .list-group {
    display: ruby;
    flex-direction: row; /* Erlaubt das Umfließen der Items, wenn der Platz nicht ausreicht */
    gap: 10px; /* Optional: Definiert den Abstand zwischen den Items */

}

.inventory-items {
    display: flex;
    flex-direction: column; /* Falls notwendig */
    width: 100%; /* Stelle sicher, dass der Container die volle Breite einnimmt */
    max-height: 70vh;
    overflow-y: auto;
}
