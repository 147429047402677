.crafting-tab .card {
    margin-bottom: 20px; 
    box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* Leichter Schatten für Tiefe */
}

.crafting-tab {
    overflow-y: auto;
    max-height: 95vh;
}

.crafting-label {
    color: #fff; /* Dunkle Schriftfarbe für guten Kontrast */
}

.requirement-red {
    color: #dc3545; /* Rote Schriftfarbe für fehlende Ressourcen */
}

.requirement-green {
    color: #28a745; /* Grüne Schriftfarbe für ausreichende Ressourcen */
}
