.miningScreen {
    height: 67vh; /* Mobile Ansicht */
  }
  
@media (min-width: 768px) {
    .miningScreen {
        width: 70%;
        height: 100vh; /* Desktop Ansicht */
        float: left;
    }
}

.mining-screen {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .grid-row {
    display: flex;
    width: 100%;
  }
  
  .ore {
    flex: 1; /* Erlaubt jedem Ore, den verfügbaren Platz gleichmäßig zu nutzen */
    max-width: 100%; /* Stellt sicher, dass Ores nicht über ihre Zelle hinausgehen */
  }
  