.purchase-tracker {
    display: flex;
    gap: 2px;
    margin-bottom: 10px;
}

.tracker-square {
    width: 20px;
    height: 20px;
    background-color: #ddd;
}

.tracker-square.purchased {
    background-color: #4CAF50; /* Grüne Farbe zur Markierung bereits gekaufter Einheiten */
}
