.equipment-slots {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    margin-bottom: 20px;
}

.equipment-layout {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.equipment-row {
    display: flex;
    justify-content: center;
    gap: 20px;
}

.helmet-row, .boots-row {
    justify-content: center;
}

.main-row {
    /* Anpassungen, falls erforderlich */
}

.relic-row {
    /*justify-content: space-between;*/
    width: 100%; /* Stelle sicher, dass Relikte am linken und rechten Rand positioniert werden */
}