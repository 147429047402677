.refinery-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.refinery-inputs, .refinery-output {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.fa-arrow-right {
    color: #ccc;
    font-size: 24px;
}

/* Anpassungen für die ProgressBar */
.progress {
    height: 20px;
    background-color: #f5f5f5;
    border-radius: 5px;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}

.progress-bar {
    background-color: #007bff;
    transition: width 0.6s ease;
}

/* Tooltip-Anpassungen, falls benötigt */
.tooltip-inner {
    background-color: #333;
    color: #fff;
    padding: 5px 10px;
    border-radius: 4px;
}

.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
    border-top-color: #333;
}
