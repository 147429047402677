.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 100vw;;
  }
  
  .miningScreen {
    flex: 2;
    height: 100vh;
  }
  
  .upgradeScreen {
    flex: 1;
    overflow: hidden;
    width: 100%;
  }
  
  .statusEffects {
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    overflow-x: auto;
  }
  
  /*.creditsDisplay {
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
  }*/
  
  /* Für Desktop-Ansicht */
  @media (min-width: 768px) {
    .container {
      flex-direction: row;
      width: 100%;
      max-width: 100vw;
    }
  
    .miningScreen {
      flex: 1;
      height: 100vh;
    }
  
    .upgradeScreen {
      flex: 1;
      position: relative; /* Neben dem Mining Screen */
    }
  
    .statusEffects{
      height: 60px;
    }
  }
  